import React from "react"
import Container from '@components/atoms/Container';
import IconLink from '@components/atoms/IconLink';
import TrackingLink from '@components/atoms/TrackingLink';
import LanguagePicker from "@components/organisms/LanguagePicker";
import UsSiteModal from "@components/organisms/UsSiteModal";
import languageCode from "@helpers/languageCode"
import textProcessor from '@helpers/mikanTextSplitter.js';
import footerSettings from '@netlify/site-settings/footer.yml';
import socialLinks from '@netlify/site-settings/social-media-links.yml';
/* eslint-disable */
import LogoInverted from '-!svg-react-loader!@images/hudl-logo-inverted.svg';
import IconNewWindow from '-!svg-react-loader!@images/icon-new-window.svg';
import IconGlobe from '-!svg-react-loader!@images/icon-globe.svg';
import IconTwitter from '-!svg-react-loader!@images/icon-twitter.svg';
import IconFacebook from '-!svg-react-loader!@images/icon-facebook.svg';
import IconInstagram from '-!svg-react-loader!@images/icon-instagram.svg';
/* eslint-enable */

import "./style.footer.scss";

const Footer = ({lang = 'ja', pageLangs}) => {

    const currentYear = new Date().getFullYear();

    const listLink = (menuItem, index) => {
        return (
            <li key={index.toString()}>
                {
                    menuItem.newWindow
                        ? (
                            <IconLink to={menuItem.path} external={menuItem.external} newWindow={menuItem.newWindow}>
                                <span>{textProcessor(lang, menuItem.label)}</span>
                                <IconNewWindow />
                            </IconLink>
                        )
                        : <TrackingLink component="footer_list_item" to={menuItem.path}>{menuItem.label}</TrackingLink>
                }
            </li>
        )
    }

    const socialLink = (menuItem, index) => {
        let icon = null;
        switch (menuItem.label) {
        case 'Twitter':
            icon = <IconTwitter />
            break;
        case 'Facebook':
            icon = <IconFacebook />
            break;
        case 'Instagram':
            icon = <IconInstagram />
            break;
        default:
            throw new Error(`Social Link type: ${menuItem.label} not found.`);
        }
        return (
            <li key={index.toString()}>
                <TrackingLink to={menuItem.url} newPage={menuItem.newWindow.toString()}>
                    {icon}
                </TrackingLink>
            </li>
        )
    }

    const language = languageCode(lang);

    const nav1 = footerSettings[language].footerNav1.map(listLink);
    const nav2 = footerSettings[language].footerNav2.map(listLink);
    const nav3 = footerSettings[language].footerPostNav.map(listLink);
    const social = socialLinks.map(socialLink);

    return (
        <footer className="hudl-footer">
            <Container>
                <div className="hudl-footer__column">
                    <LogoInverted className="hudl-footer__logo" />
                    <p className="hudl-footer-intro zeta-type hudl-margin-y--md">{textProcessor(lang, footerSettings[language].brandDescription)}</p>
                </div>
                <div className="hudl-footer__column hudl-footer__column--list">
                    <nav>
                        <ul>
                            {nav1}
                        </ul>
                    </nav>
                </div>
                <div className="hudl-footer__column hudl-footer__column--list">
                    <nav>
                        <ul>
                            {nav2}
                        </ul>
                    </nav>
                </div>
                <div className="hudl-footer__column">
                    <nav>
                        <ul>
                            <li>
                                <LanguagePicker pageLangs={pageLangs} lang={lang} />
                            </li>
                            <li>
                                <UsSiteModal lang={lang} />
                            </li>
                        </ul>
                    </nav>
                </div>
                <div className="hudl-footer__copyright-bar">
                    <div className="hudl-footer__copyright-bar--left-side">
                        <p className="type-zeta">
                            &copy;
                            {` 2007-${currentYear}. All rights reserved.`}
                        </p>
                        <nav>
                            <ul className="hudl-footer__copyright-bar__links">
                                {nav3}
                            </ul>
                        </nav>
                    </div>
                    <div className="hudl-footer__copyright-bar--right-side">
                        <nav>
                            <ul>
                                {social}
                            </ul>
                        </nav>
                    </div>
                </div>
            </Container>
        </footer>
    );
};

Footer.defaultProps = {
    type: "footer"
};

export default Footer;
