import React, { useState } from 'react';
import Button from '@components/atoms/Button';
import Container from '@components/atoms/Container';
import TrackingLink from '@components/atoms/TrackingLink';
import languageCode from '@helpers/languageCode';
import textProcessor from '@helpers/mikanTextSplitter.js';
import headerSettings from '@netlify/site-settings/header.yml';
import Helmet from 'react-helmet';
/* eslint-disable */
import Logo from '-!svg-react-loader!@images/hudl-logo.svg';
import Chevron from '-!svg-react-loader!@images/hudl-chevron.svg';
import UsSiteModal from '@components/organisms/UsSiteModal';
import LanguagePicker from '@components/organisms/LanguagePicker';

import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
    AccordionItemState
} from 'react-accessible-accordion';
/* eslint-enable */
import "./style.navigation.scss";

const Navigation = ({lang = 'ja', pageLangs}) => {

    const [hudlHamburgerMenuOpen, sethudlHamburgerMenu] = useState(false);
    const togglehudlHamburgerMenu = () => {
        sethudlHamburgerMenu(!hudlHamburgerMenuOpen)
    }

    const hudlHamburgerMenuKeyDown = (e) => {
        if (e.keyCode === 13 || e.keyCode === 32) {
            togglehudlHamburgerMenu();
        }
    }

    const language = languageCode(lang === null ? 'ja' : lang);

    const mobileNav = headerSettings[language].headerMainNav.map((menuItem, index) => {
        const hasSubNav = typeof menuItem.subnav !== 'undefined';
        let subMenuHeight = 0;

        if (hasSubNav) {
            // Calculate height of hidden subnav for animation requirements
            // 56 = <li> height
            // 32 = <ul> vertical padding
            subMenuHeight = `${56 * menuItem.subnav.length + 32}px`;
        }

        if (menuItem.type === 'link') {
            return (
                <AccordionItem key={`${menuItem.path}-${index.toString()}`} className="hudl-nav__list-item--mobile">
                    <AccordionItemState>
                        {({expanded}) => (
                            <>
                                <AccordionItemHeading>
                                    <AccordionItemButton>
                                        {hasSubNav === false && (
                                            <TrackingLink component="top_nav" to={menuItem.path} className="hudl-nav__link--mobile"><span>{menuItem.label}</span></TrackingLink>
                                        )}
                                        {hasSubNav === true && (
                                            <p className={`hudl-nav__link--mobile ${expanded === true ? ' hudl-nav__link--expanded' : ''}`}>
                                                {menuItem.label}
                                                <Chevron fill="#fff" />
                                            </p>
                                        )}
                                    </AccordionItemButton>
                                </AccordionItemHeading>
                                {hasSubNav === true && (
                                    <AccordionItemPanel style={{height: expanded === true ? subMenuHeight : 0}}>
                                        <ul className="hudl-sub-nav--mobile">
                                            {menuItem.subnav.map(subItem => (
                                                <li key={subItem.label.toLowerCase().replace(/\s/g, '')} className="hudl-sub-nav__item--mobile">
                                                    <TrackingLink component="sub_nav" to={subItem.path} className="hudl-sub-nav__link--mobile">{subItem.label}</TrackingLink>
                                                </li>
                                            ))}
                                        </ul>
                                    </AccordionItemPanel>
                                )}
                            </>
                        )}
                    </AccordionItemState>
                </AccordionItem>
            );
        }
        if (menuItem.type === 'button') {
            return <li key={`${menuItem.path}-${index.toString()}`} className="hudl-margin-x--xl hudl-margin-top--xl"><Button component="top_nav" variant="primary-button-light" to={menuItem.path}>{menuItem.label}</Button></li>;
        }
        return null;
    });

    const mainNav = headerSettings[language].headerMainNav.map((menuItem, index) => {
        const hasSubNav = typeof menuItem.subnav !== 'undefined';
        if (menuItem.type === 'link') {
            return (
                <li key={`${menuItem.path}-${index.toString()}`} className="hudl-nav__list-item--desktop">
                    {hasSubNav === false && (
                        <TrackingLink component="top_nav" to={menuItem.path} className="hudl-nav__link--desktop">
                            <span>
                                {textProcessor(lang, menuItem.label)}
                            </span>
                        </TrackingLink>
                    )}
                    {hasSubNav === true && (
                        <span className="hudl-nav__link--desktop">
                            <span>
                                {textProcessor(lang, menuItem.label)}
                                <Chevron />
                            </span>
                        </span>
                    )}
                    {hasSubNav === true && (
                        <ul className="hudl-sub-nav--desktop">
                            {menuItem.subnav.map(subItem => (
                                <li key={subItem.label.toLowerCase().replace(/\s/g, '')} className="hudl-sub-nav__item--desktop">
                                    <TrackingLink component="sub_nav" to={subItem.path} className="hudl-sub-nav__link--desktop">{subItem.label}</TrackingLink>
                                </li>
                            ))}
                        </ul>
                    )}
                </li>
            );
        }
        if (menuItem.type === 'button') {
            return <li key={`${menuItem.path}-${index.toString()}`} className="hudl-nav__button--desktop"><Button component="top_nav" variant="primary-button-dark" to={menuItem.path}>{textProcessor(lang, menuItem.label)}</Button></li>;
        }
        return null;
    });

    const preNav2 = <TrackingLink component="top_nav" to={headerSettings[language].headerPreNav2[0].path}>{headerSettings[language].headerPreNav2[0].label}</TrackingLink>;

    return (
        <>
            <Helmet>
                <body className={hudlHamburgerMenuOpen ? 'scroll-lock' : ''} />
            </Helmet>

            <div className="hudl-navigation">

                <div className={hudlHamburgerMenuOpen ? 'hudl-menu--mobile hudl-menu--mobile--open' : 'hudl-menu--mobile'}>
                    <nav className="hudl-nav--mobile">
                        <Accordion className="hudl-nav__list--mobile" allowZeroExpanded>
                            {mobileNav}
                        </Accordion>
                    </nav>
                    <div className="hudl-bottom-nav--mobile">
                        <div className="hudl-container hudl-padding-x--lg">
                            <UsSiteModal lang={lang} />
                            {preNav2}
                            <LanguagePicker pageLangs={pageLangs} lang={lang} />
                        </div>
                    </div>
                </div>


                <div className="hudl-pre-nav">
                    <Container>
                        <div className="right-side">
                            <UsSiteModal lang={lang} />
                            {preNav2}
                            <LanguagePicker pageLangs={pageLangs} lang={lang} />
                        </div>
                    </Container>
                </div>

                <div className="hudl-menu--desktop">
                    <Container>
                        <TrackingLink component="top_nav" className="hudl-logo" to={`/${lang}/`}>
                            <Logo />
                        </TrackingLink>
                        <button type="button" className={hudlHamburgerMenuOpen ? 'hudl-menu-icon hudl-menu-icon--open' : 'hudl-menu-icon'} onClick={togglehudlHamburgerMenu} onKeyPress={hudlHamburgerMenuKeyDown}>
                            <span aria-hidden="true" className="line" />
                            <span aria-hidden="true" className="line" />
                            <span aria-hidden="true" className="line" />
                            <span aria-hidden="true" className="line" />
                        </button>
                        <nav className="hudl-nav--desktop">
                            <ul className="hudl-nav__list--desktop">
                                {mainNav}
                            </ul>
                        </nav>
                    </Container>
                </div>
            </div>
        </>
    );
};

Navigation.defaultProps = {
    type: 'navigation',
    lang: 'ja'
};

export default Navigation;
