import React from "react";

import "./style.container.scss";

const Container = ({children, className, size}) => {
    const classes = ['hudl-container', size ? `hudl-container--${size}` : '', 'hudl-margin-x--auto hudl-padding-x--md', className];
    return (
        <div className={classes.join(" ")}>
            {children}
        </div>
    );
};

Container.defaultProps = {
    type: "container"
};

export default Container;
