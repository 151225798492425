import React, { useState } from 'react';
import Button from "@components/atoms/Button";
import IconLink from '@components/atoms/IconLink';
import Modal from "@components/molecules/Modal";
import chosenLanguage from "@helpers/languageChecker";
import textProcessor from '@helpers/mikanTextSplitter.js';
import headerSettings from '@netlify/site-settings/header.yml';
import headerTextConfig from '@netlify/site-settings/hudl-us-popup.yml';
/* eslint-disable */
import IconNewWindow from '-!svg-react-loader!@images/icon-new-window.svg';
/* eslint-enable */

import "./style.usSiteModal.scss";

const UsSiteModal = ({
    className=null,
    lang = 'ja'
}) => {

    const [isOpen, setIsOpen] = useState(false);
    const classes = ['hudl-us-site-modal'];

    return (
        <>
            <IconLink onClick={() => setIsOpen(true)} button>
                {headerSettings[chosenLanguage(lang)].headerPreNav1[0].label}
                <IconNewWindow />
            </IconLink>
            {isOpen && (
                <Modal onClose={() => setIsOpen(false)} className={className}>
                    <div className={classes.join(" ")}>
                        <h3 className="hudl-us-site-modal__title hudl-gamma-type hudl-margin-bottom--none">{textProcessor(lang, headerTextConfig[chosenLanguage(lang)].heading)}</h3>
                        <p className="hudl-us-site-modal__text hudl-margin-y--sm  hudl-margin-y--md--mobile">{textProcessor(lang, headerTextConfig[chosenLanguage(lang)].content)}</p>
                        <div className="hudl-us-site-modal__button-wrapper hudl-margin-top--sm">
                            <Button component="us_site_modal" variant="primary-button-dark" newPage="true" to="https://www.hudl.com" className="hudl-us-site-modal__button">{textProcessor(lang, headerTextConfig[chosenLanguage(lang)].ctaText)}</Button>
                        </div>
                    </div>
                </Modal>
            )}
        </>
    )
};

export default UsSiteModal;
