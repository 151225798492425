import { useStaticQuery, graphql } from "gatsby";

function GetSiteMetadata() {
    return useStaticQuery(
        graphql`
            {
                site {
                    siteMetadata {
                        locale
                        siteName
                        title
                        description
                        siteUrl
                        keywords
                        ogimage
                    }
                }
            }
        `
    );
}

export default GetSiteMetadata;
