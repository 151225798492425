import React from "react"
import { window, document, exists } from 'browser-monads';
import { useScrollTracker } from 'react-scroll-tracker';

import "./style.index.scss";

const Layout = ({ children, page }) => {

    if (exists(window)) {
        useScrollTracker([25, 50, 75, 100], ({scrollY, scrollPercent}) => {
            window.dataLayer.push({
                'event': 'scroll_tracking',
                'attributes': {
                    'pixels': `${(document.body.scrollHeight / 100) * scrollPercent}px`,
                    'distance': `${scrollY}%`,
                    'label': page.title
                }
            });
        });
    }

    return (
        <div>
            <main className="hudl-main-container">{children}</main>
        </div>
    )
}
export default Layout
