import React from 'react';
import Mikan from 'mikanjs';

// Regex to find Japanese text - We've added a negative look ahead to ignore latin symbols that have been added as the
// supporting library doesn't know what to do with them.
const findJapaneseTextInMixedContent = /((?![a-zA-Z0-9()～,=.:。、「」<>*"/\\^¢#�&% !|\][）（：’⁄£$@？?ー—–-]+)\S)+/g;

// Function that runs the Mikan function only on Japanese text.
// This function finds the Japanese text within mixed content, and only
// runs the replace function on that text.
const replaceJapaneseTextInMixedContent = ({text, fn, type}) => {
    switch (type) {
    // We do a slightly different check for strings and JSX content.
    case 'string':
        return text
            .replace(findJapaneseTextInMixedContent, match =>
                fn.split(match)
                    .map(content => `<span class="split-text" role="presentation">${content}</span>`
                    ).join("")
            );
    // We do a slightly different check for strings and JSX content.
    case 'jsx':
        return text.split(" ").map((word, index, array) => {
            if (word.match(findJapaneseTextInMixedContent)) {
                try {
                    return fn.split(word).map((content) => {
                        return <span key={content} role="presentation" className="split-text">{content}</span>;
                    });
                }
                catch (e) {
                    console.error(word, 'match:', word.match(findJapaneseTextInMixedContent), e);
                }
            }
            if (index !== array.length) {
                // eslint-disable-next-line prefer-template
                return word + " ";
            }
            return word;
        })
    default:
        console.error("No Type Passed In");
        return null;
    }
}

/**
 * @param {Object} options - Object of html code containing Japanese text.
 * @param {string} options.jaWords - The html block.
 * @param {string} options.fistElements - Used to store the opening html elements as the function loops through the nesting.
 * @param {string} options.lastElements - Used to store the closing html elements as the function loops through the nesting.
 * @returns {string} Returns a html block with Japanese characters split into words.
 * @description Converts a string of Japanese language and splits the japanese characters into words.
 */
function splitJapaneseText({jaWords, fn}) {

    return jaWords
        .replace(findJapaneseTextInMixedContent, match => {
            let results = null;
            try {
                results = fn.split(match)
                    .map(content => `<span class="split-text" role="presentation">${content}</span>`
                    ).join("");
            } catch (e) {
                console.log('##################################');
                console.log('Breaking content ---> ', match, ' Error: ', e);
                console.log('##################################');

            }
            return results;
        }
        )
}

const textProcessor = (lang, words) => {
    let asObject = false;

    if (typeof words === 'object') {
        asObject = true;
    }

    if (words && typeof words !== 'undefined') {
        if (lang === 'ja') {
            // This checks whether the text is coming in as a string or an object (html elements).
            if (asObject === true) {

                // Run the recursive splitJapaneseText function to split the Japanese text
                // the options are passed into the object.
                const splitWordsContent = splitJapaneseText({
                    jaWords: words.contents,
                    fn: Mikan
                });

                // Here we are just assinging the splitWordsContent that we just created
                // to words.content so that we can return it.
                // eslint-disable-next-line no-param-reassign
                words.contents = splitWordsContent;
                return words.contents;
            }
            // If its not an object, the function still runs on the string.
            return replaceJapaneseTextInMixedContent({text: words, fn: Mikan, type: 'jsx'});
        }
        return words;
    }
    return words;
}

export default textProcessor;
