/**
 * @param {string} language The language code is passed to the function from the page context.
 * @returns {string} Returns the page language in standard English, ie. Japanese, not JA.
 */
const languageCode = (language) => {
    switch(language) {
    case 'en':
        return 'english';
    default:
        return 'japanese';
    }
}

export default languageCode;