import React from "react";
import TrackingLink from "@components/atoms/TrackingLink";

import "./style.button.scss";

const Button = ({
    className = "",
    children,
    name,
    to = '',
    variant="primary",
    newPage = '',
    type,
    component = '',
    onClick,
}) => {
    const classes = ['hudl-button hudl-body-type', `hudl-button--${variant}`, className]

    if(to !== '') {
        return (
            <TrackingLink to={to} newPage={newPage} className={classes.join(" ")} component={component}>
                {children}
            </TrackingLink>
        )
    }

    if (type === "submit") {
        return (
            <input className={classes.join(" ")} type="submit" value="Submit" />
        );
    }
    return (
        <button className={classes.join(" ")} type="button" variant={variant || "button"} name={name} onClick={onClick}>
            {children}
        </button>
    );
};

Button.defaultProps = {
    variant: "button"
};

export default Button;
